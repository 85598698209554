import React from 'react';
import { Col, Row } from 'antd';

// Hooks
import { useBusinessClientsList } from '../../hooks/BusinessClients/useBusinessClientsList';

// Components
import { Container } from '../../components/UI/Base';
import { Table } from '../../components/UI/Table/Table';
import { Spinner } from '../../components/UI/Spinner/Spinner';
import { FormDrawer } from '../../components/UI/Drawer/FormDrawer';
import { BreadcrumbMenu } from '../../components/UI/Breadcrumb/BreadcrumbMenu';

export const BusinessClientsPage = () => {
  const {
    updating,
    getBreadcrumbMenuProps,
    getBusinessClientsDataProps,
    getBusinessClientsTableProps,
    getBusinessClientsCrudProps,
    getFormDrawerProps,
  } = useBusinessClientsList();

  return (
    <Spinner spinning={updating}>
      <BreadcrumbMenu {...getBreadcrumbMenuProps()} />

      <Container>
        <Row>
          <Col span={24}>
            <Table
              {...getBusinessClientsDataProps()}
              {...getBusinessClientsTableProps()}
              {...getBusinessClientsCrudProps()}
            />
          </Col>
        </Row>
      </Container>

      <FormDrawer {...getFormDrawerProps()} />
    </Spinner>
  );
};
