export interface IntroText {
  Language: string;
  IntroTitle: string;
  IntroSubtitle: string;
  IntroSubtext: string;
  CompletionTitle: string;
  CompletionSubtitle: string;
}

export const DefaultIntroTexts: Record<string, IntroText> = {
  nl: {
    IntroTitle: `<h1><span style="font-size: 38px;">Welkom bij </span><span style="color: #12a3c8;font-size: 38px;">BlueKiosk</span></h1>`,
    IntroSubtitle: `<h4><span style="font-size: 20px;">Wij heten u van harte welkom op onze demo.</span></h4>`,
    IntroSubtext: `Hier bouwen we prachtige applicaties om het leven van onze klanten eenvoudiger en gemakkelijker te maken!`,
    CompletionTitle: `Helemaal klaar!`,
    CompletionSubtitle: `Uw rechten zijn aangepast.`,
    Language: '',
  },
  en: {
    IntroTitle: `<h1><span style="font-size: 38px;">Welcome to </span><span style="color: #12a3c8;font-size: 38px;">BlueKiosk</span></h1>`,
    IntroSubtitle: `<h4><span style="font-size: 20px;">We are glad to welcome you at our demo.</span></h4>`,
    IntroSubtext: `Here we build beautifully designed applications to make the lives of our customers more simplified and easier!`,
    CompletionTitle: `All set!`,
    CompletionSubtitle: `The rights on your credential have been changed.`,
    Language: '',
  },
};

export interface KioskFile {
  FormFile: File;
  MediaType: KioskMediaType;
  Url: string;
  MimeType: string;
  Language: string;
}

export enum KioskIcon {
  None = 0,
  Door = 1,
  Card = 2,
}

export interface KioskActionText {
  Language: string;
  Title: string;
  Subtext: string;
  ButtonText: string;
  LoadingText: string;
  CompletionText: string;
}

export interface KioskAction {
  Id?: string;
  ActionId: string;
  AllowSkip: boolean;
  Order: number;
  Icon: KioskIcon;
  Texts: Array<KioskActionText>;
}

export enum KioskMediaType {
  Banner = 0,
  SecurityRegulation = 1,
  GuestInstructions = 2,
  Nda,
}

export interface Kiosk {
  Id: string;
  Name: string;
  EnableSecurityRegulations: boolean;
  EnableGuestInstructions: boolean;
  EnableGuestReservations: boolean;
  Texts: Array<IntroText>;
  Media: Array<KioskFile>;
  SecurityRegulations: Array<KioskFile>;
  GuestInstructions: Array<KioskFile>;
  Actions: Array<KioskAction>;
  TenantId: string | null;
  EnableNda: boolean;
}

export interface DeleteKioskFileRequest extends Kiosk {
  MediaType: KioskMediaType;
  Language: string;
}

export const KioskIconOptions = [
  { value: KioskIcon.None, label: 'None' },
  { value: KioskIcon.Door, label: 'Door' },
  { value: KioskIcon.Card, label: 'Card' },
];
