/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-param-reassign */

import { createSlice, PayloadAction } from '@reduxjs/toolkit';

// Models
import { IdentityUser } from '../../models/IdentityUser';
import { SignalRAction } from '../../models/SignalRAction';

// State
interface IdentityUsersState {
  updating: boolean;
  error: boolean;
  tableCustomFilters: CustomFilters;
}

interface CustomFilters {
  roles: string[];
}

const initialState: IdentityUsersState = {
  updating: false,
  error: false,
  tableCustomFilters: { roles: [] },
};

// Name
const storeName = '@IDENTITY_USERS';

// Redux Actions|Reducers
const identityUsersSlice = createSlice({
  name: storeName,
  initialState,
  reducers: {
    // Create
    createIdentityUser: (state, action: PayloadAction<IdentityUser>) => {
      state.updating = true;
      state.error = false;
    },
    identityUserCreated: (state, action: PayloadAction<SignalRAction>) => {
      state.updating = false;
      state.error = false;
    },
    identityUserNotCreated: (state, action: PayloadAction<SignalRAction>) => {
      state.updating = false;
      state.error = true;
    },

    // Activation
    sendInvitationMail: (state, action: PayloadAction<IdentityUser>) => {
      state.updating = true;
      state.error = false;
    },
    invitationMailSent: (state, action: PayloadAction<SignalRAction>) => {
      state.updating = false;
      state.error = false;
    },
    invitationMailNotSent: (state, action: PayloadAction<SignalRAction>) => {
      state.updating = false;
      state.error = true;
    },

    // Table custom filters
    updateCustomTableFilters: (state, action: PayloadAction<CustomFilters>) => {
      state.tableCustomFilters = action.payload;
    },
  },
});

// Export Actions
export const {
  // Create
  createIdentityUser,
  identityUserCreated,
  identityUserNotCreated,
  // Activation
  sendInvitationMail,
  invitationMailSent,
  invitationMailNotSent,
  // Table custom filters
  updateCustomTableFilters,
} = identityUsersSlice.actions;

// Export Reducer
export default identityUsersSlice.reducer;
