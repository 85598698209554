export enum AccessControlSystem {
  Axis = 1,
  Paxton = 2,
  SaltoSpace = 3,
  SaltoKs = 4,
  Aeos = 5,
  ScheidtAndBachmann = 6,
  Genetec = 7,
}

export interface AccessControlSystemType {
  Name: string;
  Value: number;
  AccessControlSystem: AccessControlSystem;
}

export const AccessControlSystemTypes: AccessControlSystemType[] = [
  {
    Name: 'Axis',
    Value: 1,
    AccessControlSystem: AccessControlSystem.Axis,
  },
  {
    Name: 'Paxton',
    Value: 2,
    AccessControlSystem: AccessControlSystem.Paxton,
  },
  {
    Name: 'SALTO Space',
    Value: 3,
    AccessControlSystem: AccessControlSystem.SaltoSpace,
  },
  {
    Name: 'SALTO KS',
    Value: 4,
    AccessControlSystem: AccessControlSystem.SaltoKs,
  },
  {
    Name: 'Nedap AEOS',
    Value: 5,
    AccessControlSystem: AccessControlSystem.Aeos,
  },
  {
    Name: 'Scheidt & Bachmann',
    Value: 6,
    AccessControlSystem: AccessControlSystem.ScheidtAndBachmann,
  },
  {
    Name: 'Genetec',
    Value: 7,
    AccessControlSystem: AccessControlSystem.Genetec,
  },
];
