import React, { useCallback, useMemo } from 'react';
import { Col, Form, Input, Row } from 'antd';
import styled from 'styled-components';
import { useIntl } from 'react-intl';

// Types
import { FormOptions, UseFormProps } from '../../types/Table';

// Models
import { BusinessClientFormValues } from '../../models/BusinessClient';

// Hooks
import { useAppDispatch, useAppSelector } from '../App/useRedux';

// Actions
import { createBusinessClient } from '../../store/BusinessClients/BusinessClients.redux';

// Components
import { Translated } from '../../components/UI/Core';
import { Spinner } from '../../components/UI/Spinner/Spinner';

// Styled
const StyledForm = styled(Form)`
  display: flex;
  flex-direction: column;
  height: 100%;
`;

// Hook
export const useBusinessClientsRedux = <T extends object>() => {
  // Intl
  const intl = useIntl();

  // Redux
  const dispatch = useAppDispatch();
  const updating = useAppSelector(({ businessClients }) => businessClients?.updating ?? false);
  const error = useAppSelector(({ businessClients }) => businessClients?.error ?? false);

  // Submit Handling
  const onSubmit = useCallback(
    (businessClient: BusinessClientFormValues) => {
      dispatch(createBusinessClient(businessClient));
    },
    [dispatch]
  );

  // Form
  const CreateBusinessClientForm = useCallback(
    ({ form }: UseFormProps<T>) => (
      <StyledForm form={form} layout="vertical" onFinish={(values) => onSubmit(values as BusinessClientFormValues)}>
        <Spinner spinning={updating}>
          <Row>
            <Col>
              <Form.Item
                name="Name"
                label={<Translated id="businessClients.form.name" />}
                rules={[{ required: true, message: intl.formatMessage({ id: 'form.warnings.required' }) }]}
              >
                <Input placeholder="Bluefield Smart Access" />
              </Form.Item>
            </Col>
          </Row>

          <Row>
            <Col>
              <Form.Item
                name="Description"
                label={<Translated id="businessClients.form.description" />}
                rules={[
                  {
                    required: true,
                    message: intl.formatMessage({ id: 'form.warnings.required' }),
                  },
                ]}
              >
                <Input placeholder="Noah's playground" />
              </Form.Item>
            </Col>
          </Row>

          <Row>
            <Col>
              <Form.Item
                name="TenantName"
                label={<Translated id="businessClients.form.tenantName" />}
                rules={[
                  {
                    required: true,
                    message: intl.formatMessage({ id: 'form.warnings.required' }),
                  },
                ]}
              >
                <Input placeholder="Bluefield Smart Access" />
              </Form.Item>
            </Col>
          </Row>

          <Row>
            <Col>
              <Form.Item
                name="CompanyName"
                label={<Translated id="businessClients.form.companyName" />}
                rules={[
                  {
                    required: true,
                    message: intl.formatMessage({ id: 'form.warnings.required' }),
                  },
                ]}
              >
                <Input placeholder="Bluefield Smart Access" />
              </Form.Item>
            </Col>
          </Row>

          <Row>
            <Col>
              <Form.Item
                name="LocationName"
                label={<Translated id="businessClients.form.locationName" />}
                rules={[
                  {
                    required: true,
                    message: intl.formatMessage({ id: 'form.warnings.required' }),
                  },
                ]}
              >
                <Input placeholder="Bluefield Smart Access" />
              </Form.Item>
            </Col>
          </Row>
        </Spinner>
      </StyledForm>
    ),
    [onSubmit, updating, intl]
  );

  // Form Options
  const formOptions = useMemo(
    () =>
      ({
        endpoint: 'BusinessClients',
        Form: CreateBusinessClientForm,
        labels: {
          createButton: <Translated id="businessClients.form.create" />,
          drawerForm: <Translated id="businessClients.form.create" />,
          submitButton: <Translated id="form.createButton" />,
        },
      } as FormOptions<T>),
    [CreateBusinessClientForm]
  );

  return useMemo(
    () => ({
      formOptions,
      updating,
      error,
    }),
    [formOptions, updating, error]
  );
};
