import React, { Dispatch, SetStateAction, useCallback, useEffect, useMemo, useState } from 'react';
import styled from 'styled-components';
import { Button, Col, Divider, Radio, Row, Switch, Tabs } from 'antd';
import Title from 'antd/lib/typography/Title';
import Dragger from 'antd/lib/upload/Dragger';
import { InboxOutlined } from '@ant-design/icons';

// Components
import { Widget } from '../UI/Widget/Widget';
import { Translated } from '../UI/Core';
import { useKiosksCustomBranding } from '../../hooks/Kiosks/useKiosksCustomBranding';
import { DefaultIntroTexts, Kiosk, KioskAction, KioskMediaType } from '../../models/Kiosk';
import { Spinner } from '../UI/Spinner/Spinner';
import { KioskMessageEditor } from './KioskMessageEditor';
import { ApiEndpoints } from '../../data/ApiEndpoints';
import { useData } from '../../hooks/App/useData';
import { ExpandableListItem } from './ExpandableListItem';
import { useAppDispatch } from '../../hooks/App/useRedux';
import { updateKioskActions } from '../../store/Kiosks/Kiosks.redux';

// Styled
const StyledWidget = styled(Widget)`
  & .ant-card-body {
    padding: 0;
  }
`;
const StyledTabs = styled(Tabs)`
  & .ant-tabs-nav-wrap {
    margin-left: 24px;
  }

  & .ant-tabs-nav {
    height: 58px;
  }

  & .ant-tabs-content {
    padding: 0 24px 16px;
  }
`;

// Props
interface KiosksCustomBrandingProps {
  pending: boolean;
  updating: boolean;
  kioskData: Kiosk | undefined;
  selectedLanguage: string;
  setSelectedLanguage: Dispatch<SetStateAction<string>>;
  setKioskData: Dispatch<SetStateAction<Kiosk | undefined>>;
  setTabId: Dispatch<SetStateAction<number>>;
  tabId: number;
  onTabChange: (event: string) => void;
}

// Component
export const KiosksCustomBranding = ({
  pending,
  updating,
  kioskData,
  selectedLanguage,
  setSelectedLanguage,
  setKioskData,
  onTabChange,
}: KiosksCustomBrandingProps) => {
  const { data: actions, fetch: fetchActions } = useData(ApiEndpoints.Actions.list, null);
  const dispatch = useAppDispatch();
  // State
  const [selectedFormLanguage, setSelectedFormLanguage] = useState<string>(selectedLanguage);
  const [enableSecurityRegulations, setEnableSecurityRegulations] = useState(false);
  const [enableGuestInstructions, setEnableGuestInstructions] = useState(false);
  const [enableNda, setEnableNda] = useState(false);

  // Hook
  const {
    currentText,
    handleUpload,
    handleDelete,
    setKioskText,
    onSubmit,
    onSecurityRegulationSave,
    onGuestInstructionsSave,
    addAction,
    onGuestNdaSave,
  } = useKiosksCustomBranding({
    allowUpdates: !pending && selectedFormLanguage === selectedLanguage,
    kioskData,
    selectedLanguage,
    setKioskData,
    enableSecurityRegulations,
    enableGuestInstructions,
    enableNda,
  });

  // Effects
  useEffect(() => {
    fetchActions();
  }, [fetchActions]);

  // When the selected language changes, the form will still send an update with the old callbacks.
  // Because of this we need to make sure the callbacks don't accept anything (allowUpdates) before actually switching the language.
  // This makes sure that if an old callback is used for some reason, it is not functional.
  useEffect(() => {
    if (selectedFormLanguage !== selectedLanguage && selectedFormLanguage) {
      setSelectedLanguage(selectedFormLanguage);
    }
  }, [selectedFormLanguage, selectedLanguage, setSelectedLanguage]);

  useEffect(() => {
    if (selectedFormLanguage !== selectedLanguage) {
      return;
    }
    if (currentText.IntroTitle === '' && currentText.IntroSubtitle === '' && currentText.IntroSubtext === '') {
      const defaultText = DefaultIntroTexts[selectedLanguage];
      if (!defaultText) {
        return;
      }

      currentText.IntroTitle = `<p>${defaultText.IntroTitle}</p>`;
      currentText.IntroSubtext = `<p>${defaultText.IntroSubtext}</p>`;
      currentText.IntroSubtitle = `<p>${defaultText.IntroSubtitle}</p>`;
      currentText.CompletionTitle = `<p>${defaultText.CompletionTitle}</p>`;
      currentText.CompletionSubtitle = `<p>${defaultText.CompletionSubtitle}</p>`;
      setKioskText(currentText);
    }
  }, [currentText, setKioskText, selectedFormLanguage, selectedLanguage]);

  // Components
  const KioskBrandingFormBanner = useMemo(
    () => (
      <Row>
        <Col span={24}>
          <Dragger
            name="file"
            multiple={false}
            action=""
            beforeUpload={() => false}
            onChange={(event) => {
              handleUpload(KioskMediaType.Banner, event.fileList[event.fileList.length - 1]?.originFileObj);
            }}
          >
            <p className="ant-upload-drag-icon">
              <InboxOutlined />
            </p>
            <p className="ant-upload-text">
              <Translated id="kiosks.upload.image.text" />
            </p>
            <p className="ant-upload-hint">
              <Translated id="kiosks.upload.image.text2" />
            </p>
            <p className="ant-upload-hint">
              <Translated id="kiosks.upload.image.text3" />
            </p>
          </Dragger>
        </Col>
      </Row>
    ),
    [handleUpload]
  );

  const KioskBrandingFormLanguage = useMemo(
    () => (
      <Row style={{ marginTop: '16px' }}>
        <Col span={24}>
          <Radio.Group
            value={selectedLanguage}
            buttonStyle="solid"
            onChange={(event) => setSelectedFormLanguage(event.target.value)}
            size="large"
            style={{ display: 'flex' }}
          >
            <Radio.Button value="en" style={{ flex: 1 }}>
              <Translated id="kiosks.branding.tab.english" />
            </Radio.Button>
            <Radio.Button value="nl" style={{ flex: 1 }}>
              <Translated id="kiosks.branding.tab.netherlands" />
            </Radio.Button>
          </Radio.Group>
        </Col>
      </Row>
    ),
    [setSelectedFormLanguage, selectedLanguage]
  );

  const KioskBrandingFormTexts = useMemo(
    () => (
      <Row>
        <Col span={24}>
          <Divider />
          <Title style={{ color: '#3f8bab', fontSize: '1.2rem' }}>
            <Translated id="kiosks.welcome.text" />
          </Title>
          <Divider />
        </Col>

        <KioskMessageEditor
          valueText={currentText.IntroTitle ?? ''}
          labelId="kiosks.introTitle"
          onChange={(value) => {
            currentText.IntroTitle = value;
            setKioskText(currentText);
          }}
          pending={pending}
        />

        <KioskMessageEditor
          valueText={currentText?.IntroSubtitle ?? ''}
          labelId="kiosks.subTitle"
          onChange={(value) => {
            currentText.IntroSubtitle = value;
            setKioskText(currentText);
          }}
          pending={pending}
        />

        <KioskMessageEditor
          valueText={currentText?.IntroSubtext ?? ''}
          labelId="kiosks.introSubtext"
          onChange={(value) => {
            currentText.IntroSubtext = value;
            setKioskText(currentText);
          }}
          pending={pending}
        />

        <Col span={24}>
          <Divider />
          <Title style={{ color: '#3f8bab', fontSize: '1.2rem' }}>
            <Translated id="kiosks.completion.text" />
          </Title>
          <Divider />
        </Col>

        <KioskMessageEditor
          valueText={currentText?.CompletionTitle ?? ''}
          labelId="kiosks.completion.title"
          onChange={(value) => {
            currentText.CompletionTitle = value;
            setKioskText(currentText);
          }}
          pending={pending}
        />

        <KioskMessageEditor
          valueText={currentText?.CompletionSubtitle ?? ''}
          labelId="kiosks.completion.subtitle"
          onChange={(value) => {
            currentText.CompletionSubtitle = value;
            setKioskText(currentText);
          }}
          pending={pending}
        />

        <Col span={24}>
          <Button type="primary" onClick={onSubmit}>
            <Translated id="kiosks.branding.save.button" />
          </Button>
          {!!kioskData?.Media?.find((x) => x.MediaType === KioskMediaType.Banner && x.Language === selectedLanguage)
            ?.Url && (
            <Button type="primary" onClick={() => handleDelete(KioskMediaType.Banner)}>
              <Translated id="kiosk.remove.file.button" />
            </Button>
          )}
        </Col>
      </Row>
    ),
    [currentText, pending, onSubmit, kioskData?.Media, setKioskText, selectedLanguage, handleDelete]
  );

  const KioskBrandingFormSpinner = useMemo(
    () => (
      <Spinner spinning={updating}>
        <Title style={{ color: '#3f8bab', fontSize: '1.2rem' }}>
          <Translated id="kiosks.introText.header" />
        </Title>

        <Divider />

        {KioskBrandingFormLanguage}

        <Divider />

        {KioskBrandingFormBanner}

        {KioskBrandingFormTexts}
      </Spinner>
    ),
    [KioskBrandingFormBanner, KioskBrandingFormLanguage, KioskBrandingFormTexts, updating]
  );

  const onSecurityRegulationsToggleChange = (checked: boolean) => {
    setEnableSecurityRegulations(checked);
  };

  const onGuestInstructionsToggleChange = (checked: boolean) => {
    setEnableGuestInstructions(checked);
  };

  const onGuestNdaToggleChange = (checked: boolean) => {
    setEnableNda(checked);
  };

  const KioskSecurityRegulations = useMemo(
    () => (
      <>
        <Row>
          <Col span={24}>
            <Translated id="kiosk.guest.security.regulations.text" />
            <Switch
              defaultChecked={kioskData?.EnableSecurityRegulations}
              style={{ marginLeft: '8px' }}
              onChange={onSecurityRegulationsToggleChange}
            />
          </Col>
        </Row>

        <Divider />

        {KioskBrandingFormLanguage}

        <Divider />

        <Row>
          <Col span={24}>
            <Dragger
              name="file"
              multiple={false}
              action=""
              beforeUpload={() => false}
              onChange={(event) => {
                handleUpload(
                  KioskMediaType.SecurityRegulation,
                  event.fileList[event.fileList.length - 1]?.originFileObj
                );
              }}
            >
              <p className="ant-upload-drag-icon">
                <InboxOutlined />
              </p>
              <p className="ant-upload-text">
                <Translated id="kiosks.upload.image.text" />
              </p>
              <p className="ant-upload-hint">
                <Translated id="kiosks.upload.image.text2" />
              </p>
              <p className="ant-upload-hint">
                <Translated id="kiosks.upload.image.text3" />
              </p>
            </Dragger>
            {enableSecurityRegulations &&
              !kioskData?.Media?.find(
                (x) => x.MediaType === KioskMediaType.SecurityRegulation && x.Language === selectedLanguage
              )?.Url && (
                <p className="ant-upload-hint" style={{ color: 'red' }}>
                  <Translated id="kiosk.upload.file.warning" />
                </p>
              )}
          </Col>
        </Row>

        <Row>
          <Col span={24} style={{ marginTop: '34px' }}>
            <Button
              type="primary"
              onClick={onSecurityRegulationSave}
              disabled={
                enableSecurityRegulations &&
                !kioskData?.Media?.find(
                  (x) => x.MediaType === KioskMediaType.SecurityRegulation && x.Language === selectedLanguage
                )?.Url
              }
            >
              <Translated id="kiosks.branding.save.button" />
            </Button>
            {!!kioskData?.Media?.find(
              (x) => x.MediaType === KioskMediaType.SecurityRegulation && x.Language === selectedLanguage
            )?.Url && (
              <Button type="primary" onClick={() => handleDelete(KioskMediaType.SecurityRegulation)}>
                <Translated id="kiosk.remove.file.button" />
              </Button>
            )}
          </Col>
        </Row>
      </>
    ),
    [
      kioskData?.EnableSecurityRegulations,
      kioskData?.Media,
      enableSecurityRegulations,
      KioskBrandingFormLanguage,
      onSecurityRegulationSave,
      handleUpload,
      selectedLanguage,
      handleDelete,
    ]
  );

  const KioskGuestInstructions = useMemo(
    () => (
      <>
        <Row>
          <Col span={24}>
            <Translated id="kiosk.guest.instructions.switch.text" />
            <Switch
              defaultChecked={kioskData?.EnableGuestInstructions}
              style={{ marginLeft: '8px' }}
              onChange={onGuestInstructionsToggleChange}
            />
          </Col>
        </Row>

        <Divider />

        {KioskBrandingFormLanguage}

        <Divider />

        <Row>
          <Col span={24}>
            <Dragger
              name="file"
              multiple={false}
              action=""
              beforeUpload={() => false}
              onChange={(event) => {
                handleUpload(
                  KioskMediaType.GuestInstructions,
                  event.fileList[event.fileList.length - 1]?.originFileObj
                );
              }}
            >
              <p className="ant-upload-drag-icon">
                <InboxOutlined />
              </p>
              <p className="ant-upload-text">
                <Translated id="kiosks.upload.image.text" />
              </p>
              <p className="ant-upload-hint">
                <Translated id="kiosks.upload.image.text2" />
              </p>
              <p className="ant-upload-hint">
                <Translated id="kiosks.upload.image.text3" />
              </p>
            </Dragger>
            {enableGuestInstructions &&
              !kioskData?.Media?.find(
                (x) => x.MediaType === KioskMediaType.GuestInstructions && x.Language === selectedLanguage
              )?.Url && (
                <p className="ant-upload-hint" style={{ color: 'red' }}>
                  <Translated id="kiosk.upload.file.warning" />
                </p>
              )}
          </Col>
        </Row>

        <Row>
          <Col span={24} style={{ marginTop: '34px' }}>
            <Button
              type="primary"
              onClick={onGuestInstructionsSave}
              disabled={
                enableGuestInstructions &&
                !kioskData?.Media?.find(
                  (x) => x.MediaType === KioskMediaType.GuestInstructions && x.Language === selectedLanguage
                )?.Url
              }
            >
              <Translated id="kiosks.branding.save.button" />
            </Button>
            {!!kioskData?.Media?.find(
              (x) => x.MediaType === KioskMediaType.GuestInstructions && x.Language === selectedLanguage
            )?.Url && (
              <Button type="primary" onClick={() => handleDelete(KioskMediaType.GuestInstructions)}>
                <Translated id="kiosk.remove.file.button" />
              </Button>
            )}
          </Col>
        </Row>
      </>
    ),
    [
      kioskData?.EnableGuestInstructions,
      kioskData?.Media,
      enableGuestInstructions,
      KioskBrandingFormLanguage,
      onGuestInstructionsSave,
      handleUpload,
      selectedLanguage,
      handleDelete,
    ]
  );
  const [actionsData, setActions] = useState<KioskAction[]>(kioskData?.Actions || []);

  const handleActionChange = useCallback(
    (updatedAction: KioskAction) => {
      setActions((prevActions) => {
        // Initialize newActions with existing kioskData actions if prevActions are not found or empty
        let newActions = prevActions && prevActions.length > 0 ? prevActions : kioskData?.Actions || [];

        // Check if the action with the same Order exists in newActions
        const actionExists = newActions.some((action) => action.Order === updatedAction.Order);

        if (actionExists) {
          // If it exists, update the existing action
          newActions = newActions.map((action) =>
            action.Order === updatedAction.Order ? { ...action, ...updatedAction } : action
          );
        } else {
          // If it doesn't exist, add the new action to the list
          newActions = [...newActions, updatedAction];
        }

        return newActions;
      });
    },
    [kioskData]
  );

  const onActionSubmit = useCallback(() => {
    if (kioskData) {
      // Use an empty array as a fallback if kioskData.Actions is null
      const existingActions = kioskData.Actions || [];

      // Merge current state actions with existing database actions
      const allActions = [...existingActions, ...(actionsData || [])];

      // Create a map to ensure unique actions by Order
      const actionMap = new Map<number, KioskAction>();

      // Populate map with existing actions
      allActions.forEach((action) => {
        actionMap.set(action.Order, action);
      });

      // Convert map values to array and sort by Order
      const mergedActions = Array.from(actionMap.values()).sort((a, b) => a.Order - b.Order);

      // Dispatch the update with the merged actions
      dispatch(updateKioskActions({ ...kioskData, Actions: mergedActions }));
    }
  }, [actionsData, dispatch, kioskData]);

  const handleDeleteAction = useCallback(
    (order: number) => {
      if (!kioskData || !kioskData.Actions) return;

      // Filter out the action with the specified order
      const filteredActions = kioskData.Actions?.filter((item) => item.Order !== order);

      // Reassign orders to the remaining items
      const updatedActions = filteredActions.map((action, index) => ({
        ...action,
        Order: index, // Reassign order based on the new index
      }));

      // Dispatch the update with the new list of actions
      dispatch(updateKioskActions({ ...kioskData, Actions: updatedActions }));
    },
    [dispatch, kioskData]
  );

  const KioskActions = useMemo(
    () => (
      <>
        {KioskBrandingFormLanguage}
        <Divider />
        {kioskData?.Actions?.map((action) => (
          <ExpandableListItem
            key={action.Order}
            action={action}
            onActionChange={handleActionChange}
            actionData={actions}
            onDeleteAction={handleDeleteAction}
            selectedLanguage={selectedLanguage}
            selectedFormLanguage={selectedFormLanguage}
          />
        ))}
        <Row>
          <Col span={24} style={{ marginTop: '34px' }}>
            <Button
              type="primary"
              onClick={() => {
                addAction({
                  Id: undefined,
                  ActionId: '',
                  AllowSkip: false,
                  Icon: 0,
                  Order: 0,
                  Texts: [
                    {
                      Language: selectedLanguage,
                      Title: '',
                      Subtext: '',
                      ButtonText: '',
                      LoadingText: '',
                      CompletionText: '',
                    },
                  ],
                });
              }}
            >
              <Translated id="kiosks.branding.add.button" />
            </Button>
            <Button type="primary" onClick={onActionSubmit}>
              <Translated id="kiosks.branding.save.button" />
            </Button>
          </Col>
        </Row>
      </>
    ),
    [
      KioskBrandingFormLanguage,
      kioskData?.Actions,
      onActionSubmit,
      handleActionChange,
      actions,
      handleDeleteAction,
      addAction,
      selectedLanguage,
      selectedFormLanguage,
    ]
  );

  const KioskNDA = useMemo(
    () => (
      <>
        <Row>
          <Col span={24}>
            <Translated id="kiosk.nda.switch.text" />
            <Switch
              defaultChecked={kioskData?.EnableNda}
              style={{ marginLeft: '8px' }}
              onChange={onGuestNdaToggleChange}
            />
          </Col>
        </Row>

        <Divider />

        {KioskBrandingFormLanguage}

        <Divider />

        <Row>
          <Col span={24}>
            <Dragger
              name="file"
              multiple={false}
              action=""
              beforeUpload={() => false}
              onChange={(event) => {
                handleUpload(KioskMediaType.Nda, event.fileList[event.fileList.length - 1]?.originFileObj);
              }}
            >
              <p className="ant-upload-drag-icon">
                <InboxOutlined />
              </p>
              <p className="ant-upload-text">
                <Translated id="kiosks.upload.image.text" />
              </p>
              <p className="ant-upload-hint">
                <Translated id="kiosks.upload.image.text2" />
              </p>
              <p className="ant-upload-hint">
                <Translated id="kiosks.upload.image.text3" />
              </p>
            </Dragger>
            {enableNda &&
              !kioskData?.Media?.find((x) => x.MediaType === KioskMediaType.Nda && x.Language === selectedLanguage)
                ?.Url && (
                <p className="ant-upload-hint" style={{ color: 'red' }}>
                  <Translated id="kiosk.upload.file.warning" />
                </p>
              )}
          </Col>
        </Row>

        <Row>
          <Col span={24} style={{ marginTop: '34px' }}>
            <Button
              type="primary"
              onClick={onGuestNdaSave}
              disabled={
                enableNda &&
                !kioskData?.Media?.find((x) => x.MediaType === KioskMediaType.Nda && x.Language === selectedLanguage)
                  ?.Url
              }
            >
              <Translated id="kiosks.branding.save.button" />
            </Button>
            {!!kioskData?.Media?.find((x) => x.MediaType === KioskMediaType.Nda && x.Language === selectedLanguage)
              ?.Url && (
              <Button type="primary" onClick={() => handleDelete(KioskMediaType.Nda)}>
                <Translated id="kiosk.remove.file.button" />
              </Button>
            )}
          </Col>
        </Row>
      </>
    ),
    [
      kioskData?.EnableNda,
      kioskData?.Media,
      enableNda,
      KioskBrandingFormLanguage,
      onGuestNdaSave,
      handleUpload,
      selectedLanguage,
      handleDelete,
    ]
  );

  // Component Render
  return useMemo(() => {
    return (
      <StyledWidget styleName="gx-card-widget">
        <StyledTabs
          onChange={onTabChange}
          items={[
            {
              key: '0',
              label: (
                <div>
                  <i style={{ paddingLeft: 5, paddingRight: 5 }} className="icon icon-wysiwyg" />
                  <Translated id="kiosk.branding.tab" />
                </div>
              ),
              children: KioskBrandingFormSpinner,
            },
            {
              key: '1',
              label: (
                <div>
                  <i style={{ paddingLeft: 5, paddingRight: 5 }} className="icon icon-files" />
                  <Translated id="kiosk.security.regulations.tab" />
                </div>
              ),
              children: KioskSecurityRegulations,
            },
            {
              key: '2',
              label: (
                <div>
                  <i style={{ paddingLeft: 5, paddingRight: 5 }} className="icon icon-auth-screen" />
                  <Translated id="kiosk.guest.instructions.tab" />
                </div>
              ),
              children: KioskGuestInstructions,
            },
            {
              key: '3',
              label: (
                <div>
                  <i style={{ paddingLeft: 5, paddingRight: 5 }} className="icon icon-product-list" />
                  <Translated id="kiosk.nda.tab" />
                </div>
              ),
              children: KioskNDA,
            },
            {
              key: '4',
              label: (
                <div>
                  <i style={{ paddingLeft: 5, paddingRight: 5 }} className="icon icon-product-list" />
                  <Translated id="kiosk.actions.tab" />
                </div>
              ),
              children: KioskActions,
            },
          ]}
        />
      </StyledWidget>
    );
  }, [KioskBrandingFormSpinner, KioskSecurityRegulations, KioskGuestInstructions, KioskNDA, KioskActions, onTabChange]);
};
