import React from 'react';
import { v4 as uuid } from 'uuid';
import { Route } from 'react-router';

// Routes
import { getAuthorizationRoutes } from './AuthorizationRoutes';

// Components
import { AuthorizedRoute } from '../components/UI/ApiAuthorization/AuthorizedRoute';

// Pages
import { NotFoundPage } from '../pages/App/NotFoundPage';

import { DashboardPage } from '../pages/Dashboard/DashboardPage';
import { CompaniesPage } from '../pages/Companies/CompaniesPage';
import { LocationsPage } from '../pages/Locations/LocationsPage';
import { TenantsPage } from '../pages/Tenants/TenantsPage';
import { ScheduleConfigurationsPage } from '../pages/ScheduleConfigurations/ScheduleConfigurationsPage';
import { ScheduleConfigurationDetailPage } from '../pages/ScheduleConfigurations/ScheduleConfigurationDetailPage';
import { ServiceConfigurationsPage } from '../pages/ServiceConfigurations/ServiceConfigurationsPage';
import { ServiceConfigurationDetailPage } from '../pages/ServiceConfigurations/ServiceConfigurationDetailPage';
import { ApiConfigurationsPage } from '../pages/ApiConfigurations/ApiConfigurationsPage';
import { ApiConfigurationDetailPage } from '../pages/ApiConfigurations/ApiConfigurationDetailPage';
import { AccessProfilesPage } from '../pages/AccessProfiles/AccessProfilesPage';
import { AccessProfileDetailPage } from '../pages/AccessProfiles/AccessProfileDetailPage';
import { IdentifierTypesPage } from '../pages/IdentifierTypes/IdentifierTypesPage';
import { IdentifierTypeDetailPage } from '../pages/IdentifierTypes/IdentifierTypeDetailPage';
import { IdentityUsersPage } from '../pages/IdentityUsers/IdentityUsersPage';
import { IdentityUserDetailPage } from '../pages/IdentityUsers/IdentityUserDetailPage';
import { ApiClientsPage } from '../pages/ApiClients/ApiClientsPage';
import { KiosksPage } from '../pages/Kiosks/KiosksPage';
import { KiosksDetailPage } from '../pages/Kiosks/KiosksDetailPage';
import { AzureTenantsPage } from '../pages/AzureTenants/AzureTenantsPage';
import { AzureTenantDetailPage } from '../pages/AzureTenants/AzureTenantDetailPage';
import { CalendarEventConfigurationsPage } from '../pages/CalendarEventConfigurations/CalendarEventConfigurationsPage';
import { CalendarEventConfigurationDetailPage } from '../pages/CalendarEventConfigurations/CalendarEventConfigurationDetailPage';
import { ActionsPage } from '../pages/Actions/ActionsPage';
import { ActionsDetailPage } from '../pages/Actions/ActionDetailPage';
import { BusinessClientsPage } from '../pages/BusinessClients/BusinessClientsPage';

export const routes = [
  // Home
  <Route key={uuid()} path="/" element={<AuthorizedRoute path="/" />}>
    {/* Dashboard */}
    <Route path="/" key={uuid()} element={<DashboardPage />} />
    <Route path="/Dashboard" key={uuid()} element={<DashboardPage />} />
    {/* Tenants */}
    <Route path="/Tenants" key={uuid()} element={<TenantsPage />} />
    {/* Companies */}
    <Route path="/Companies" key={uuid()} element={<CompaniesPage />} />
    {/* Locations */}
    <Route path="/Locations" key={uuid()} element={<LocationsPage />} />
    {/* ScheduleConfigurations */}
    <Route path="/ScheduleConfigurations" key={uuid()} element={<ScheduleConfigurationsPage />} />
    <Route path="/ScheduleConfigurations/:id" key={uuid()} element={<ScheduleConfigurationDetailPage />} />
    {/* ServiceConfigurations */}
    <Route path="/ServiceConfigurations" key={uuid()} element={<ServiceConfigurationsPage />} />
    <Route path="/ServiceConfigurations/:id" key={uuid()} element={<ServiceConfigurationDetailPage />} />
    {/* ApiConfigurations */}
    <Route path="/ApiConfigurations" key={uuid()} element={<ApiConfigurationsPage />} />
    <Route path="/ApiConfigurations/:id" key={uuid()} element={<ApiConfigurationDetailPage />} />
    {/* AccessProfiles */}
    <Route path="/AccessProfiles" key={uuid()} element={<AccessProfilesPage />} />
    <Route path="/AccessProfiles/:id" key={uuid()} element={<AccessProfileDetailPage />} />
    {/* IdentifierTypes */}
    <Route path="/IdentifierTypes" key={uuid()} element={<IdentifierTypesPage />} />
    <Route path="/IdentifierTypes/:id" key={uuid()} element={<IdentifierTypeDetailPage />} />
    {/* Kiosks */}
    <Route path="/Kiosks" key={uuid()} element={<KiosksPage />} />
    <Route path="/Kiosks/:id" key={uuid()} element={<KiosksDetailPage />} />
    {/* IdentityUsers */}
    <Route path="/IdentityUsers" key={uuid()} element={<IdentityUsersPage />} />
    <Route path="/IdentityUsers/:id" key={uuid()} element={<IdentityUserDetailPage />} />
    {/* Clients */}
    <Route path="/ApiClients" key={uuid()} element={<ApiClientsPage />} />
    {/* AzureTenant */}
    <Route path="/AzureTenants" key={uuid()} element={<AzureTenantsPage />} />
    <Route path="/AzureTenants/:id" key={uuid()} element={<AzureTenantDetailPage />} />
    {/* CalendarEventConfigurations */}
    <Route path="/CalendarEventConfigurations" key={uuid()} element={<CalendarEventConfigurationsPage />} />
    <Route path="/CalendarEventConfigurations/:id" key={uuid()} element={<CalendarEventConfigurationDetailPage />} />
    {/* Actions */}
    <Route path="/Actions" key={uuid()} element={<ActionsPage />} />
    <Route path="/Actions/:id" key={uuid()} element={<ActionsDetailPage />} />
    {/* Business Clients */}
    <Route path="/BusinessClients" key={uuid()} element={<BusinessClientsPage />} />
    {/* default */}
    <Route path="*" key={uuid()} element={<NotFoundPage />} />
  </Route>,

  // authorization
  getAuthorizationRoutes(),
];
