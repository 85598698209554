import React from 'react';

import { TableColumn } from '../../types/Table';
import { IdentityUser } from '../../models/IdentityUser';
import { Translated } from '../../components/UI/Core';
import { TranslatedTag } from '../../components/UI/Tag/TranslatedTag';
import { TextTag } from '../../components/UI/Tag/TextTag';
import { IdentityRoles } from '../../models/enums/IdentityRoles';

export const identityUserTableColumns: Array<TableColumn<IdentityUser>> = [
  {
    Header: <Translated id="identityUsers.id" />,
    id: 'Id',
    accessor: 'Id',
    isVisible: false,
    disableSortBy: true,
    disableFilters: true,
  },
  {
    Header: <Translated id="identityUsers.name" />,
    id: 'UserName',
    accessor: 'UserName',
    isVisible: false,
  },
  {
    Header: <Translated id="identityUsers.email" />,
    id: 'Email',
    accessor: 'Email',
  },
  {
    Header: <Translated id="identityUsers.confirmed" />,
    id: 'Confirmed',
    accessor: (row) =>
      row.Confirmed ? (
        <TranslatedTag color="green" translationId="app.yes" />
      ) : (
        <TranslatedTag color="red" translationId="app.no" />
      ),
    disableFilters: true,
    disableSortBy: true,
  },
  {
    Header: <Translated id="identityUsers.2fa" />,
    id: 'TwoFactorEnabled',
    accessor: (row) =>
      row.TwoFactorEnabled ? (
        <TranslatedTag color="green" translationId="app.yes" />
      ) : (
        <TranslatedTag color="red" translationId="app.no" />
      ),
    disableFilters: true,
    disableSortBy: true,
  },
  {
    Header: <Translated id="identityUsers.roles" />,
    id: 'Roles',
    accessor: (row) =>
      row.Roles?.map((role) => {
        const identityRole = IdentityRoles.find((r) => r.IdentityRole === role);
        return <TextTag key={role} color={identityRole?.Color ?? ''} text={<Translated id={identityRole?.NameId} />} />;
      }),
    disableSortBy: true,
    disableFilters: true,
  },
];
