import { all, put, takeEvery, takeLatest } from 'redux-saga/effects';
import { PayloadAction } from '@reduxjs/toolkit';

// Services
import { ApiRequestType, apiService } from '../../services/ApiService/ApiService';
import { notificationService } from '../../services/Notifications/NotificationService';

// Models
import { IdentityUser } from '../../models/IdentityUser';
import { SignalRAction } from '../../models/SignalRAction';

// Redux
import {
  createIdentityUser,
  identityUserCreated,
  identityUserNotCreated,
  invitationMailNotSent,
  invitationMailSent,
  sendInvitationMail,
} from './IdentityUsers.redux';

// **************************************************
// ********************* CREATE *********************

// Worker Sagas
function* createIdentityUserSaga() {
  yield takeEvery(createIdentityUser.type, createIdentityUserRequest);
}

function* identityUserCreatedSaga() {
  yield takeLatest(identityUserCreated.type, createIdentityUserResponse);
}

function* identityUserNotCreatedSaga() {
  yield takeLatest(identityUserNotCreated.type, createIdentityUserError);
}

// Request
function* createIdentityUserRequest(action: PayloadAction<IdentityUser>) {
  try {
    const { payload: identityUser } = action;
    yield apiService.execute({
      url: 'IdentityUsers',
      method: ApiRequestType.POST,
      data: identityUser,
    });
  } catch ({ message }) {
    yield put({ type: identityUserNotCreated.type, payload: { msg: { message } } });
  }
}

// Response
function createIdentityUserResponse(action: PayloadAction<SignalRAction>) {
  notificationService.showSuccess('identityUsers.notifications.create');
  action.payload.history.push(`/IdentityUsers/${action.payload.msg.entityId}`);
}

// Error
function createIdentityUserError(action: PayloadAction<SignalRAction>) {
  notificationService.showErrorWithContent('identityUsers.notifications.createFailed', action?.payload?.msg.message);
}

// **************************************************
// ****************** ACTIVATION ********************

// Worker Sagas
function* sendActivationMailSaga() {
  yield takeEvery(sendInvitationMail.type, sendActivationMailRequest);
}

function* activationMailSentSaga() {
  yield takeLatest(invitationMailSent.type, sendActivationMailUserResponse);
}

function* activationMailNotSentSaga() {
  yield takeLatest(invitationMailNotSent.type, sendActivationMailUserError);
}

// Request
function* sendActivationMailRequest(action: PayloadAction<IdentityUser>) {
  try {
    const { payload: identityUser } = action;
    yield apiService.execute({
      url: `IdentityUsers/${identityUser.Id}/ResendInvitation`,
      method: ApiRequestType.POST,
      data: {
        IdentityId: identityUser.Id,
      },
    });
  } catch ({ message }) {
    yield put({ type: invitationMailNotSent.type, payload: { msg: { message } } });
  }
}

// Response
function sendActivationMailUserResponse() {
  notificationService.showSuccess('identityUsers.notifications.activationSent');
}

// Error
function sendActivationMailUserError(action: PayloadAction<SignalRAction>) {
  notificationService.showErrorWithContent(
    'identityUsers.notifications.activationNotSent',
    action?.payload?.msg.message
  );
}

// **************************************************
// ********************* EXPORT SAGAS ***************

export default function* sagas() {
  yield all([
    // Create
    createIdentityUserSaga(),
    identityUserCreatedSaga(),
    identityUserNotCreatedSaga(),
    // Activation
    sendActivationMailSaga(),
    activationMailSentSaga(),
    activationMailNotSentSaga(),
  ]);
}
