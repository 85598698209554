import React, { useCallback, useMemo } from 'react';
import { Button, Divider, Form, Modal } from 'antd';
import { Store } from 'antd/lib/form/interface';
import styled from 'styled-components';
import { useIntl } from 'react-intl';
import { IdcardOutlined } from '@ant-design/icons';

// Models
import { PACSSettingsFormValues } from '../../../models/PACSSettingFormValues';
import { ServiceConfigurationResponse } from '../../../models/ServiceConfiguration';
import { AccessControlSystem } from '../../../models/enums/AccessControlSystemTypes';

// Redux
import { useAppDispatch, useAppSelector } from '../../App/useRedux';
import {
  assignPACSServiceConfiguration,
  updatePACSAdvancedSettings,
} from '../../../store/ServiceConfigurations/PACSSettings/PACSSettings.redux';

// Hooks
import { useAxisSettings } from './Axis/useAxisSettings';
import { usePaxtonSettings } from './Paxton/usePaxtonSettings';
import { useAeosSettings } from './Aeos/useAeosSettings';
import { useSaltoSpaceSettings } from './SaltoSpace/useSaltoSpaceSettings';
import { useSaltoKsSettings } from './SaltoKs/useSaltoKsSettings';
import { useScheidtAndBachmannSettings } from './ScheidtAndBachmann/useScheidtAndBachmannSettings';
import { useGenetecSettings } from './Genetec/useGenetecSettings';

// Components
import { Translated } from '../../../components/UI/Core';
import { useAdvancedPACSSettings } from './AdvancedSettings/useAdvancedPACSSettings';

const { Item } = Form;
const { confirm } = Modal;

// Styled
const NoBottomMarginFormItem = styled(Item)`
  margin-bottom: 0;
`;
const StyledButton = styled(Button)`
  margin-bottom: 0;
`;

// Props
interface PACSSettingsReduxProps {
  serviceConfigurationResponse: ServiceConfigurationResponse | null;
}

interface FormSettingsProps {
  SettingsForm: () => JSX.Element;
  initialValues?: Store | undefined;
}

interface AssignConfirmProps {
  pacsSettingsFormValues: PACSSettingsFormValues;
}

// Hook
export const usePACSSettingsRedux = ({ serviceConfigurationResponse }: PACSSettingsReduxProps) => {
  // Intl
  const intl = useIntl();

  // Redux
  const dispatch = useAppDispatch();
  const updating = useAppSelector(({ pacsSettings }) => pacsSettings?.updating ?? false);
  const error = useAppSelector(({ pacsSettings }) => pacsSettings?.error ?? false);

  // Confirmation Modals
  const showAssignConfirm = useCallback(
    ({ pacsSettingsFormValues }: AssignConfirmProps) => {
      if (pacsSettingsFormValues) {
        confirm({
          title: intl.formatMessage({
            id: 'serviceConfigurations.pacsSettings.confirm.sync',
          }),
          icon: <IdcardOutlined />,
          content: intl.formatMessage({
            id: 'serviceConfigurations.pacsSettings.confirm.syncSub',
          }),
          okText: intl.formatMessage({
            id: 'app.yes',
            defaultMessage: 'Yes',
          }),
          cancelText: intl.formatMessage({
            id: 'app.no',
            defaultMessage: 'No',
          }),
          okType: 'primary',
          onOk: () => dispatch(assignPACSServiceConfiguration(pacsSettingsFormValues)),
          onCancel: () => null,
        });
      }
    },
    [dispatch, intl]
  );

  // PACS Hook Forms
  const { AxisSettingsForm, initialValues: axisInitialValues } = useAxisSettings({
    serviceConfigurationResponse,
  });

  const { PaxtonSettingsForm, initialValues: paxtonInitialValues } = usePaxtonSettings({
    serviceConfigurationResponse,
  });

  const { SaltoSpaceSettingsForm, initialValues: saltoSpaceInitialValues } = useSaltoSpaceSettings({
    serviceConfigurationResponse,
  });

  const { SaltoKsSettingsForm, initialValues: saltoKsInitialValues } = useSaltoKsSettings({
    serviceConfigurationResponse,
  });

  const { AeosSettingsForm, initialValues: aeosInitialValues } = useAeosSettings({
    serviceConfigurationResponse,
  });

  const { ScheidtAndBachmannSettingsForm, initialValues: scheidtAndBachmannInitialValues } =
    useScheidtAndBachmannSettings({
      serviceConfigurationResponse,
    });

  const { GenetecSettingsForm, initialValues: genetecInitialValues } = useGenetecSettings({
    serviceConfigurationResponse,
  });

  const { AdvancedPACSSettingsForm, initialValues: advancedPACSSettingsInitialValues } = useAdvancedPACSSettings({
    serviceConfigurationResponse,
  });

  let settings: FormSettingsProps;
  switch (serviceConfigurationResponse?.ServiceConfiguration.AccessControlSystemType) {
    case AccessControlSystem.Axis:
      settings = {
        SettingsForm: AxisSettingsForm,
        initialValues: axisInitialValues,
      };
      break;
    case AccessControlSystem.Paxton:
      settings = {
        SettingsForm: PaxtonSettingsForm,
        initialValues: paxtonInitialValues,
      };
      break;
    case AccessControlSystem.SaltoSpace:
      settings = {
        SettingsForm: SaltoSpaceSettingsForm,
        initialValues: saltoSpaceInitialValues,
      };
      break;
    case AccessControlSystem.SaltoKs:
      settings = {
        SettingsForm: SaltoKsSettingsForm,
        initialValues: saltoKsInitialValues,
      };
      break;
    case AccessControlSystem.Aeos:
      settings = {
        SettingsForm: AeosSettingsForm,
        initialValues: aeosInitialValues,
      };
      break;
    case AccessControlSystem.ScheidtAndBachmann:
      settings = {
        SettingsForm: ScheidtAndBachmannSettingsForm,
        initialValues: scheidtAndBachmannInitialValues,
      };
      break;
    case AccessControlSystem.Genetec:
      settings = {
        SettingsForm: GenetecSettingsForm,
        initialValues: genetecInitialValues,
      };
      break;
    default:
      settings = {
        SettingsForm: () => <div />,
        initialValues: {},
      };
      break;
  }
  const { initialValues, SettingsForm } = settings;

  // Components
  const PACSSettingManagementForm = useCallback(
    () => (
      <Form
        layout="vertical"
        initialValues={initialValues}
        onFinish={(values: PACSSettingsFormValues) =>
          serviceConfigurationResponse &&
          showAssignConfirm({
            pacsSettingsFormValues: {
              ...values,
              SettingsData: JSON.stringify(values.SettingsData),
            },
          })
        }
      >
        <SettingsForm />

        <Divider />

        {/* Action Buttons */}
        <NoBottomMarginFormItem>
          <StyledButton type="primary" htmlType="submit">
            <Translated id="form.confirmButton" />
          </StyledButton>
        </NoBottomMarginFormItem>
      </Form>
    ),
    [serviceConfigurationResponse, showAssignConfirm, initialValues, SettingsForm]
  );

  const AdvancedPACSSettingManagementForm = useCallback(
    () => (
      <Form
        layout="vertical"
        initialValues={advancedPACSSettingsInitialValues}
        onFinish={(values: PACSSettingsFormValues) =>
          serviceConfigurationResponse && dispatch(updatePACSAdvancedSettings(values))
        }
      >
        <AdvancedPACSSettingsForm />

        <Divider />

        {/* Action Buttons */}
        <NoBottomMarginFormItem>
          <StyledButton type="primary" htmlType="submit">
            <Translated id="app.save" />
          </StyledButton>
        </NoBottomMarginFormItem>
      </Form>
    ),
    [advancedPACSSettingsInitialValues, AdvancedPACSSettingsForm, serviceConfigurationResponse, dispatch]
  );

  return useMemo(
    () => ({
      PACSSettingManagementForm,
      AdvancedPACSSettingManagementForm,
      updating,
      error,
    }),
    [PACSSettingManagementForm, AdvancedPACSSettingManagementForm, updating, error]
  );
};
