import React, { useMemo } from 'react';
import { MenuProps } from 'antd';

// Components
import { Translated } from '../../components/UI/Core/Translated';

// Constants
import { BasicIcon } from '../../components/UI/Icon/BasicIcon';
import { AppMenuItem } from '../../components/App/AppMenu/AppMenuItem';
import { AuthIdentityRoles } from '../../constants/Settings/AuthSettings';
import { useAppSelector } from '../../hooks/App/useRedux';

interface AuthenticatedMenuItem {
  key: string;
  label: React.JSX.Element | string;
  className?: string;
  popupClassName?: string;
  allowedRoles: string[];
  icon?: React.JSX.Element | string;
  children?: AuthenticatedMenuItem[];
}

export const authenticatedMenuItems: AuthenticatedMenuItem[] = [
  {
    key: 'Dashboard',
    label: <AppMenuItem id="dashboard" to="/Dashboard" icon="data-display" />,
    className: 'gx-menu-horizontal gx-submenu-popup-curve gx-inside-submenu-popup-curve',
    allowedRoles: [AuthIdentityRoles.TenantAdministrator],
  },

  {
    key: 'Management',
    label: <Translated id="appMenu.management" />,
    icon: <BasicIcon className="icon icon-lock-screen" />,
    popupClassName: 'gx-menu-horizontal gx-submenu-popup-curve gx-inside-submenu-popup-curve',
    allowedRoles: [AuthIdentityRoles.TenantAdministrator],
    children: [
      {
        key: 'IdentityUsers',
        label: <AppMenuItem id="identityUsers" to="/IdentityUsers" icon="auth-screen" />,
        allowedRoles: [AuthIdentityRoles.TenantAdministrator],
      },
      {
        key: 'ApiClients',
        label: <AppMenuItem id="apiClients" to="/ApiClients" icon="user" />,
        allowedRoles: [AuthIdentityRoles.TenantAdministrator],
      },
      {
        key: 'Tenants',
        label: <AppMenuItem id="tenants" to="/Tenants" icon="crm" />,
        popupClassName: 'gx-menu-horizontal gx-submenu-popup-curve gx-inside-submenu-popup-curve',
        allowedRoles: [AuthIdentityRoles.TenantAdministrator],
        children: [
          {
            key: 'Companies',
            label: <AppMenuItem id="companies" to="/Companies" icon="company" />,
            allowedRoles: [AuthIdentityRoles.TenantAdministrator],
          },
          {
            key: 'Locations',
            label: <AppMenuItem id="locations" to="/Locations" icon="location" />,
            allowedRoles: [AuthIdentityRoles.TenantAdministrator],
          },
        ],
      },
    ],
  },

  {
    key: 'Configurations',
    label: <Translated id="appMenu.configurations" />,
    icon: <BasicIcon className="icon icon-extra-components" />,
    popupClassName: 'gx-menu-horizontal gx-submenu-popup-curve gx-inside-submenu-popup-curve',
    allowedRoles: [AuthIdentityRoles.TenantAdministrator],
    children: [
      {
        key: 'ServiceConfigurations',
        label: <AppMenuItem id="serviceConfigurations" to="/ServiceConfigurations" icon="chart-radar" />,
        allowedRoles: [AuthIdentityRoles.TenantAdministrator],
      },
      {
        key: 'ApiConfigurations',
        label: <AppMenuItem id="apiConfigurations" to="/ApiConfigurations" icon="chart-radar" />,
        allowedRoles: [AuthIdentityRoles.TenantAdministrator],
      },
      {
        key: 'AccessProfiles',
        label: <AppMenuItem id="accessProfiles" to="/AccessProfiles" icon="select" />,
        allowedRoles: [AuthIdentityRoles.TenantAdministrator],
      },
      {
        key: 'IdentifierTypes',
        label: <AppMenuItem id="identifierTypes" to="/IdentifierTypes" icon="product-list" />,
        allowedRoles: [AuthIdentityRoles.TenantAdministrator],
      },
      {
        key: 'Kiosks',
        label: <AppMenuItem id="kiosks" to="/Kiosks" icon="apps" />,
        allowedRoles: [AuthIdentityRoles.TenantAdministrator],
      },
      {
        key: 'AzureTenants',
        label: <AppMenuItem id="azureTenants" to="/AzureTenants" icon="auth-screen" />,
        allowedRoles: [AuthIdentityRoles.TenantAdministrator],
      },
      {
        key: 'CalendarEventConfigurations',
        label: <AppMenuItem id="calendarEventConfigurations" to="/CalendarEventConfigurations" icon="email" />,
        allowedRoles: [AuthIdentityRoles.TenantAdministrator],
      },
      {
        key: 'ScheduleConfigurations',
        label: <AppMenuItem id="scheduleConfigurations" to="/ScheduleConfigurations" icon="schedule" />,
        allowedRoles: [AuthIdentityRoles.TenantAdministrator],
      },
      {
        key: 'Actions',
        label: <AppMenuItem id="actions" to="/Actions" icon="setting" />,
        allowedRoles: [AuthIdentityRoles.TenantAdministrator],
      },
    ],
  },

  {
    key: 'SuperAdmin',
    label: <Translated id="appMenu.superAdmin" />,
    icon: <BasicIcon className="icon icon-noodles" />,
    popupClassName: 'gx-menu-horizontal gx-submenu-popup-curve gx-inside-submenu-popup-curve',
    allowedRoles: [AuthIdentityRoles.SuperAdmin],
    children: [
      {
        key: 'BusinessClients',
        label: <AppMenuItem id="businessClients" to="/BusinessClients" icon="crm" />,
        allowedRoles: [AuthIdentityRoles.SuperAdmin],
      },
    ],
  },
];

export const useMenuItems = () => {
  const userRoles = useAppSelector(({ account }) => account?.userRoles ?? []);

  return useMemo(
    () =>
      authenticatedMenuItems
        .filter((item) => userRoles.some((role) => item.allowedRoles.includes(role)))
        .map(({ allowedRoles, children, ...item }) => ({
          ...item,
          children: children
            ?.filter((child) => userRoles.some((role) => child.allowedRoles.includes(role)))
            .map(({ allowedRoles: _, ...child }) => child),
        })) as MenuProps['items'],
    [userRoles]
  );
};
