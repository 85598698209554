// Types
import { SignalREvent } from '../../models/SignalREvent';

// Actions
import {
  identityUserCreated,
  identityUserNotCreated,
  invitationMailNotSent,
  invitationMailSent,
} from './IdentityUsers.redux';

// Events
const SignalREvents = {
  IdentityUserCreated: `IdentityUserCreatedIntegrationEvent`,
  InvitationEmailSent: `InvitationEmailSentIntegrationEvent`,
};

// Exceptions
const SignalRExceptions = {
  IdentityUserNotCreated: `IdentityUserNotCreatedIntegrationException`,
  InvitationEmailNotSent: `InvitationEmailNotSentIntegrationException`,
};

// Events
export const configureSignalREvents = ({ hubConnection, store, history }: SignalREvent) => {
  // Create
  hubConnection.on(SignalREvents.IdentityUserCreated, (msg) => {
    store.dispatch(identityUserCreated({ history, msg }));
  });
  hubConnection.on(SignalRExceptions.IdentityUserNotCreated, (msg) => {
    store.dispatch(identityUserNotCreated({ history, msg }));
  });

  // Update
  hubConnection.on(SignalREvents.InvitationEmailSent, (msg) => {
    store.dispatch(invitationMailSent({ history, msg }));
  });
  hubConnection.on(SignalRExceptions.InvitationEmailNotSent, (msg) => {
    store.dispatch(invitationMailNotSent({ history, msg }));
  });
};
