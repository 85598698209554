import React, { useCallback, useMemo } from 'react';
import { Col, Form, Input, Modal, Row, Switch } from 'antd';
import { UserDeleteOutlined } from '@ant-design/icons';
import { Store } from 'antd/lib/form/interface';
import styled from 'styled-components';
import { useIntl } from 'react-intl';

// Types
import FormItemLabel from 'antd/lib/form/FormItemLabel';
import { FormOptions, UseFormProps } from '../../types/Table';

// Models
import { IdentityUser } from '../../models/IdentityUser';

// Hooks
import { useAppDispatch, useAppSelector } from '../App/useRedux';

// Actions
import { createIdentityUser, sendInvitationMail } from '../../store/IdentityUsers/IdentityUsers.redux';

// Components
import { Translated } from '../../components/UI/Core';
import { Spinner } from '../../components/UI/Spinner/Spinner';

const { confirm } = Modal;

// Styled
const StyledForm = styled(Form)`
  display: flex;
  flex-direction: column;
  height: 100%;
`;

// Props
interface IdentityUsersReduxProps {
  isEditing?: boolean;
  initialValues?: Store | undefined;
}

// Hook
export const useIdentityUsersRedux = <T extends object>({ initialValues }: IdentityUsersReduxProps = {}) => {
  // Intl
  const intl = useIntl();

  // Redux
  const dispatch = useAppDispatch();
  const updating = useAppSelector(({ identityUsers }) => identityUsers?.updating ?? false);
  const error = useAppSelector(({ identityUsers }) => identityUsers?.error ?? false);

  // Submit Handling
  const onSubmit = useCallback(
    (identityUser: IdentityUser) => {
      dispatch(createIdentityUser(identityUser));
    },
    [dispatch]
  );

  // Form
  const CreateIdentityUserForm = useCallback(
    ({ form }: UseFormProps<T>) => (
      <StyledForm
        form={form}
        layout="vertical"
        onFinish={(values) => onSubmit(values as IdentityUser)}
        initialValues={initialValues}
      >
        <Spinner spinning={updating}>
          <Form.Item name="Id" hidden>
            <Input />
          </Form.Item>
          <Row>
            <Col>
              <Form.Item
                name="Email"
                label={<Translated id="identityUsers.email" />}
                rules={[{ required: true, message: intl.formatMessage({ id: 'identityUsers.form.warnings.email' }) }]}
              >
                <Input placeholder="test@bluefield.com" />
              </Form.Item>
            </Col>

            <Col>
              <FormItemLabel label={<Translated id="identityUsers.isReservationUser" />} prefixCls="cls" />
              <Form.Item name="IsReservationUser" valuePropName="checked">
                <Switch checkedChildren={<Translated id="app.yes" />} unCheckedChildren={<Translated id="app.no" />} />
              </Form.Item>
            </Col>
          </Row>
        </Spinner>
      </StyledForm>
    ),
    [onSubmit, updating, initialValues, intl]
  );
  // Form Options
  const formOptions = useMemo(
    () =>
      ({
        endpoint: 'IdentityUsers',
        Form: CreateIdentityUserForm,
        labels: {
          createButton: <Translated id="identityUsers.form.create" />,
          drawerForm: <Translated id="identityUsers.form.create" />,
          submitButton: <Translated id="form.createButton" />,
        },
      } as FormOptions<T>),
    [CreateIdentityUserForm]
  );

  // Confirmation Modals
  const showResendInvitationConfirm = useCallback(
    (identityUser: IdentityUser) => {
      if (identityUser) {
        confirm({
          title: intl.formatMessage({
            id: 'identityUsers.confirm.sendActivation',
          }),
          icon: <UserDeleteOutlined />,
          content: intl.formatMessage({
            id: 'identityUsers.confirm.sendActivationSub',
          }),
          okText: intl.formatMessage({
            id: 'app.yes',
            defaultMessage: 'Yes',
          }),
          cancelText: intl.formatMessage({
            id: 'app.no',
            defaultMessage: 'No',
          }),
          onOk: () => dispatch(sendInvitationMail(identityUser)),
          onCancel: () => null,
        });
      }
    },
    [intl, dispatch]
  );

  return useMemo(
    () => ({
      formOptions,
      updating,
      error,
      showResendInvitationConfirm,
    }),
    [formOptions, updating, error, showResendInvitationConfirm]
  );
};
